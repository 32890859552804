import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "@/store";

export interface BillingSliceState {
  printDetails: boolean;
  printGroupedByAffiliate: boolean;
  printSummaryPage: boolean;
}

const initialState: BillingSliceState = {
  printDetails: false,
  printGroupedByAffiliate: true,
  printSummaryPage: false,
};

export const billingSlice = createSlice({
  initialState,
  name: "billing",
  reducers: {
    setPrintDetails: (state, { payload }: PayloadAction<boolean>) => {
      state.printDetails = payload;
    },
    setPrintGroupedByAffiliate: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.printGroupedByAffiliate = payload;
    },
    setPrintSummaryPage: (state, { payload }: PayloadAction<boolean>) => {
      state.printSummaryPage = payload;
    },
  },
});

export default billingSlice.reducer;

export const {
  setPrintDetails,
  setPrintSummaryPage,
  setPrintGroupedByAffiliate,
} = billingSlice.actions;

export const selectPrintDetails = (state: RootState): boolean =>
  state.billing.printDetails;

export const selectPrintGroupedByAffiliate = (state: RootState): boolean =>
  state.billing.printGroupedByAffiliate;

export const selectPrintSummaryPage = (state: RootState): boolean =>
  state.billing.printSummaryPage;
